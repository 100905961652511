<template>
  <section class="ticketForm container align-center py-6">
    <div class="bg-white shadow p-4">
      <div class="pt-4 pb-4 mb-5 border-b-2 border-gray">
        <h1 class="new-ticket-title">
          <i class="fal fa-ticket-alt"></i> {{ part=='request' ? $t("New / Change Request") : $t("New Ticket")  }}
        </h1>
      </div>

      <v-form v-model="rules.valid">
           <v-row>
            <v-col cols="3">
        <div> 
 
              <v-row>
                <v-col cols="12" class="px12py0"   v-if="userProfile == 'MANAGER' || part=='request'"
              ><span class="pt-3 title-input">{{ $t("Projects") }}</span></v-col
            >
            <v-col cols="12" class="px12py0"   v-if="userProfile == 'MANAGER'  || part=='request'">
              <v-select
                :label="$t('Projects')"
                :items="getProjects"
                outlined
                :rules="rules.reqRules"
                required
                item-text="project_name"
                item-value="record_id"
                :menu-props="{
                  zIndex: 101,
                }"
                v-model="ticket.project"
                @change="fiilterSection()"
                dense
                flat
                hide-details
              />
            </v-col>
            <v-col cols="12"  class="px12py0"   v-if="userProfile == 'MANAGER'  || part=='request'"
              ><span class="pt-3 title-input">{{ $t("Sections") }}</span></v-col
            >
            <v-col cols="12" class="px12py0"   v-if="userProfile == 'MANAGER'  || part=='request'">
              <v-select
                :label="$t('Sections')"
                :items="filteredSections"
                outlined
                required
                item-text="section_name"
                item-value="record_id"
                :menu-props="{
                  zIndex: 101,
                }"
                v-model="ticket.section"
                dense
                flat
                hide-details
              />
            </v-col>
        <v-col cols="12"   class="px12py0"
              ><span class="pt-3 title-input">{{
                $t("Brand")
              }}</span></v-col
            >
             <v-col cols="12" class="px12py0">
              
              <v-select
                :label="$t('Brand')"
                :items="getBrands()"
                outlined
                :rules="rules.reqRules"
                required
                item-text="label"
                item-value="id"
                :menu-props="{
                  zIndex: 101,
                }"
                v-model="ticket.brand"
        
                dense
                flat
                hide-details
              />
            </v-col>
            <v-col cols="12" class="px12py0"
              ><span class="pt-3 title-input">{{ $t("Category") }}</span></v-col
            >
            <v-col cols="12" class="px12py0">
              <v-select
                :label="$t('Category')"
                :items="getCategories(null)"
                outlined
                :rules="rules.reqRules"
                required
                item-text="category_name"
                item-value="inbox_category_id"
                :menu-props="{
                  zIndex: 101,
                }"
                v-model="mainCategory"
                @change="getCategories(mainCategory)"
                dense
                flat
                hide-details
              />
            </v-col>
            
            <v-col cols="12"   class="px12py0"
              ><span class="pt-3 title-input">{{
                $t("Sub Category")
              }}</span></v-col
            >
            <v-col cols="12" class="px12py0">
              <v-select
                :key="'main' + mainCategory"
                :label="$t('Sub Category')"
                :items="subCategories"
                required
                outlined
                :rules="rules.reqRules"
                :disabled="mainCategory === null"
                item-text="category_name"
                item-value="inbox_category_id"
                :menu-props="{
                  zIndex: 101,
                }"
                v-model="ticket.category"
                dense
                flat
                hide-details
              />
            </v-col>

              
             <v-col cols="12" class="px12py0">
              <span class="pt-3 title-input">{{ $t("Priority") }}</span></v-col
            >
             <v-col cols="12" class="px12py0">
  
            <v-list>
              <v-list-item-group v-model="ticket.priority">
                <v-row>
                  <v-col cols="12" class="px12py0" v-for="(item, i) in [priorities[0],priorities[1]]">
                  <v-list-item
                    :key="`item-${i}`"
                    :value="item.value"
                    active-class="primary--text text--accent-4"
                    v-if="(i==0 && userProfile == 'MANAGER') || i!=0"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="primary accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  </v-col> 
                </v-row>
              </v-list-item-group>
            </v-list>   
            </v-col>  
          </v-row>
          
        </div>
        
            </v-col>
          
           <v-col >
        <div  >
          <v-row>
            <v-col cols="12"  class="px12py0"
              ><span class="pt-3 title-input">{{ $t("Subject") }}</span></v-col
            >
            <v-col cols="12" class="px12py0">
              <v-text-field
                :label="$t('Subject')"
                outlined
                dense
                flat
                :rules="rules.nameRules"
                hide-details
                v-model="ticket.subject"
              ></v-text-field>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12" class="px12py0"
              ><span class="pt-3 title-input">{{ $t("CC") }}</span></v-col
            >
            <v-col cols="12" class="px12py0">
 
                <v-autocomplete
                     v-model="ticket.cc"
                       :search-input.sync="search"
                    :items="allUsers"
                    :loading="loading"
                    outlined
                    dense
                    chips
                    flat
                    small-chips
                      :label="$t('CC')"
                    multiple
                     item-text="email"
                     item-value="email"
               ></v-autocomplete> 
            </v-col> 
          </v-row>
        </div>

        <div class="pt-4 pb-4 border-b-2 border-gray">
          <p style="padding-top: 12px; padding-bottom: 0px;">{{ $t("Description") }}</p>

          <redactor v-model="ticket.message" :config="configOptions"></redactor>
        </div>

        <div class="pt-4 pb-4 border-b-2 border-gray">
 
          <VueFileAgent
            ref="vueFileAgent"
            :theme="'grid'"
            :multiple="true"
            :deletable="true"
            :meta="true"
            :accept="'image/*,.docx,.doc,.xls,.xlsx,.pdf,.zip'"
            :maxSize="'10MB'"
            :maxFiles="14"
            :helpText="'Choose images or files'"
            :errorText="{
              type: 'Invalid file type. Only images or zip Allowed',
              size: 'Files should not exceed 10MB in size',
            }" 
            @delete="fileDelete($event)"
            @select="filesSelected($event)"
            @beforedelete="onBeforeDelete($event)" 
            v-model="attachments"
          ></VueFileAgent>
          <!-- {{fileRecordsForUpload}}   {{attachments}} -->
        </div>
    
           
        <div class="pt-4">
          <v-btn
            color="primary"
            class="mr-5"
            rounded
            large
            @click.prevent="crateTicket"
            :disabled="!rules.valid || sending"
      
            >{{ $t("Send") }}
          </v-btn>

          <v-btn color="secondary" rounded large @click.prevent="close()"
            >{{ $t("Cancel") }}
          </v-btn>
        </div>
        </v-col>
           </v-row>
      </v-form>
    </div>
  </section>
</template>

<script>
import { CreateThreadProxy } from "@/proxies/CreateThreadProxy";
import { UploadFileProxy } from "@/proxies/UploadFileProxy";
import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import VueFileAgent from "vue-file-agent";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
Vue.use(VueFileAgent);
import {
  UsersProxy
} from '@/proxies/UsersProxy'
export default {
  name: "CreateTicket",
  data() {
    return {
      loading:false,
      allUsers:[],
      search:null,
      ticket: {
        message: "",
        subject: "",
        category: "",
        project: null,
        section: null,
        priority: null,
        brand:null
      },
      sending: false,
      priorities: [
        { value: 1, name: this.$t("Urgent"), icon: "fa-siren-on" },
        { value: 2, name: this.$t("High"), icon: "fa-star-exclamation" },
        { value: 3, name: this.$t("Medium"), icon: "" },
        { value: 4, name: this.$t("Low"), icon: "" },
      ],
      rules: {
        valid: false,
        reqRules: [(v) => !!v || "Field is required"],
        nameRules: [
          (v) => !!v || "Subject is required",
          (v) => v.length >= 5 || "Subject must be more than 5 characters",
        ],
        email: "",
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },
      mainCategory: null,
      filteredSections: [],
      subCategories: [],
      attachments: [],
      fileRecordsForUpload: [],
      configOptions: {
        plugins: ["clips", "fontsize", "fullscreen", "table", "inlinestyle"],
        buttonsHide: ["html", "align", "strikethrough"],
        buttons: [
          "redo",
          "undo",
          "format",
          "bold",
          "italic",
          "lists",
          "image",
          "file",
          "link",
          "clips",
        ],
        minHeight: "170px",
        editor: { focus: false },
      },
    };
  },
  watch:{

      attachments(val){
          console.log(val)
      },
      search(word){
        this.loading = true;
        let parameters = {
          keyword:word
        }
        return new Promise((resolve, reject) => {
              new UsersProxy() 
               .setParameters(parameters)
                .all()
                .then((response) => {
                    this.loading = false;
                    this.allUsers=  response.data
                  resolve();
                })
                .catch((err) => {
                   this.loading = false;
                    this.allUsers= []
                  reject(err.message);
                });
            });
    },
  },
  async created() {
    this.getSections();
    if(1==1){
      this.ticket.brand = this.defaultBrand ? this.defaultBrand.id : null;
    }
  },
  methods: {
    getBrands(){
      return this.mainData.assigned_brands
    },
    defaultBrand(){
      return this.mainData.assigned_brands.find(k=> k.default==1 )
    },
    close() {
      if (this.ticket.message || this.ticket.subject) {
        this.$confirm("You've unsaved data. Are you sure to cancel ? ").then(
          (res) => {
            if (res) {
              this.$router.push("/tickets");
            }
          }
        );
      } else {
        this.$router.push("/tickets");
      }
    }, 
    filesSelected: function (fileRecordsNewlySelected) { 

      },
    onBeforeDelete : function (fileRecordsNewlySelected) { 
          var i = this.attachments.indexOf(fileRecordsNewlySelected);
          this.attachments.splice(i, 1); 

      },
    fileDelete: function (fileRecord) {
          console.log("fileDelete",fileRecord)
          var i = this.attachments.indexOf(fileRecord);
          console.log(i)
          this.fileRecordsForUpload.splice(i, 1); 
    },
    uploadFile(id) { 
        this.attachments.forEach((k) => {
        let item = new FormData();
        let attachment = k;
        // console.log("attachment",attachment)
        item.append("file", attachment.file, attachment.file.name);
        item.append("messageId", id); 

        new UploadFileProxy()
          .create({
            item,
            messageId:id
          })
          .then(() => {
            if(this.part =='request'){
              this.$router.push({ name: "requests" });
            }else{
              this.$router.push({ name: "tickets" });
            }
            this.sending = false;
          }).catch(err=>{
             this.sending = false;
          }); 
      });
    },
    getSections() {
      if (this.sections && this.sections[0]) {
        this.fiilterSection();
      } else {
        this.$store.dispatch("common/sections", {});
        this.fiilterSection();
      }
    },
    fiilterSection() {
      if (this.filteredSections) {
        this.filteredSections = this.sections.filter(
          (k) => k.project === this.ticket.project
        );
      } else {
        this.filteredSections = [];
      }
    },
    getCategories(top_id) {
      if (this.categories && this.categories[0]) {
        let results = this.categories.filter((k) => {
          if (k.parent_category == top_id) {
            return k;
          }
        });
        if (top_id != null) {
          console.log("results", results);
          this.subCategories = results ? results : [];
        }
        return results;
      }
    },
    crateTicket(){
      
      this.sending = true;
      let t = {...this.ticket}
      if(t.cc){
        t.cc = t.cc.join(",")
      }
      new CreateThreadProxy()
        .create({
          item: t,
        })
        .then((result) => {

            // console.log("before file ",this.attachments)
          if (this.attachments && this.attachments[0] ) {

            //  console.log("Tickets file",this.attachments)
            this.uploadFile(result.message_id);
          } else {
            // console.log("Tickets to")
            this.sending = false;
            this.$router.push({ name: "tickets" });
          }
        }).catch((err)=>{
             this.sending = false;
        });  
    },
  },
  // components:{
  //   VueFileAgent
  // },
  computed: {
    ...mapState("common", ["users"]),
    ...mapState("common", ["categories"]),
    ...mapState("common", ["sections"]),
    ...mapState("account", ["token"]),
    ...mapGetters("common", ["getProjects"]),
    ...mapGetters("account", ["userProfile"]),
    mainData() {
      return this.$store.state.common.main;
    },
    part(){
      return this.$route.query ?  this.$route.query.part : ''
    }
  },
};
</script>

<style lang="scss">
.ticketForm {
  .new-ticket-title {
    font-weight: 600;
    font-size: 18px;
    color: var(--dark-gray);
  }

  .title-input {
    display: block;
    padding-top: 2px;
  }
}
 .mb-10 {
   margin-bottom: 0px;
 }
.px12py0{
  padding: 0px 12px!important;
} 
.file-preview{
  img, svg, video, canvas, audio, iframe, embed, object {
    display: inline-block!important;
  }
}

.v-autocomplete__content{
    z-index: 999!important;
}
</style>
